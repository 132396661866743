import { firestore } from 'firebase.config'
import { getAuth } from 'firebase/auth'
import { collection, getDocs, query } from 'firebase/firestore'
import { ItemType } from 'modal/index'
import { Dispatch } from 'redux'
import { dispatchSystem } from 'store/reducers'

export const fetchCart = () => {
    const cartInfor = sessionStorage.getItem('cart')
    if (cartInfor) {
        const cart = JSON.parse(cartInfor)
        return cart
    } else {
        sessionStorage.removeItem('cart')
        return []
    }
}

export const fetchAuth = () => {
    const auth = getAuth();
    if (auth) {
        return auth
    } else {
        return null
    }
}

export const fetchSystem = async (dispatch: Dispatch) => {
    try {
        await getDocs(query(collection(firestore, 'system')))
            .then((querySnapshot) => {
                const newItems: any[] = [];
                querySnapshot.forEach((doc) => {
                    newItems.push({ id: doc.id, ...doc.data() } as any);
                });

                if (newItems.length > 0) {
                    dispatch(dispatchSystem(newItems[0]))
                }
            })
            .catch((error) => {
                console.error('Error getting documents: ', error);
            });
    } catch (error) {

    } finally {
    }
}

export const addToCart = (item: ItemType, count: number) => {
    const existValue = sessionStorage.getItem('cart')
    if (existValue) {
        const arrayValue: any = JSON.parse(existValue)
        if (arrayValue && Array.isArray(arrayValue)) {
            const exist = arrayValue.find((x) => x.id === item.id)
            if (exist) {
                const newValue = arrayValue.map((x) => x.id === item.id ? { ...exist, qty: exist.qty + count } : x)
                sessionStorage.setItem('cart', JSON.stringify(newValue))
            } else {
                const newValue = [...arrayValue, { ...item, qty: count }]
                sessionStorage.setItem('cart', JSON.stringify(newValue))
            }
        }
    } else {
        const newValue = [{ ...item, qty: count }]
        sessionStorage.setItem('cart', JSON.stringify(newValue))
    }
};

export const listIconsStore = [
    {
        src: "/media/market/shopee.png",
        alt: 'shopee'
    },
    {
        src: "/media/market/tiki.png",
        alt: 'tiki'
    },
    {
        src: "/media/market/sendo.png",
        alt: 'sendo'
    },
    {
        src: "/media/market/lazada.png",
        alt: 'lazada'
    },
    {
        src: "/media/market/zalo-icon.png",
        alt: 'zalo'
    },
    {
        src: "/media/market/Tiktok-icon.png",
        alt: 'Tiktok'
    },
]

export const NextClick = (props: any) => {
    const { onClick } = props
    return <div
        className="slider-action section next minus"
        onClick={onClick}
    >
        {">"}
    </div>
}

export const PrevClick = (props: any) => {
    const { onClick } = props
    return <div
        className="slider-action section prev minus"
        onClick={onClick}
    >
        {"<"}
    </div>
}

export function chuanHoaChuoi(chuoi: string) {
    let chuoiKhongDau = chuoi.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    let chuoiChuanHoa = chuoiKhongDau.replace(/\s+/g, "");

    return chuoiChuanHoa;
}

export function smoothScroll(target: any) {
    const targetElement = document.getElementById(target);

    if (targetElement) {
        const offset = 100;
        const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - offset;

        window.scrollTo({
            top: targetPosition,
            behavior: 'smooth',
        });
    }
}