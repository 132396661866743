import React, { useEffect, useState } from "react";
import '../index.css'
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "firebase.config";
import { Rate } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { TypeStore } from "store/reducers";
import Slider from "react-slick";
import { NextClick, PrevClick } from "utils/index";

export function SanPhamNoiBat() {

    const [items, setItems] = useState<any>([])
    const { themelayout } = useSelector((state: TypeStore) => state)

    const responsiveSettings = [
        {
            breakpoint: 994,
            settings: {
                slidesToShow: Math.min(items.length, 3),
                slidesToScroll: Math.min(items.length, 3),
            },
        },
        {
            breakpoint: 695,
            settings: {
                slidesToShow: Math.min(items.length, 2),
                slidesToScroll: Math.min(items.length, 2),
            },
        },
        {
            breakpoint: 490,
            settings: {
                slidesToShow: Math.min(items.length, 2),
                slidesToScroll: Math.min(items.length, 2),
            },
        },
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        // autoplay: true,
        nextArrow: <NextClick />,
        prevArrow: <PrevClick />,
        slidesToShow: Math.min(items.length, 4),
        slidesToScroll: Math.min(items.length, 4),
        responsive: responsiveSettings,
    };

    const loadMore = async () => {
        let q = query(collection(firestore, 'items'), where("outstanding", "==", true));

        getDocs(q)
            .then((querySnapshot) => {
                const newItems: any[] = [];
                querySnapshot.forEach((doc) => {
                    newItems.push({ id: doc.id, ...doc.data() } as any);
                });

                if (newItems.length > 0) {
                    setItems([...items, ...newItems]);
                }
            })
            .catch((error) => {
                console.error('Error getting documents: ', error);
            });
    };

    useEffect(() => {
        loadMore();
    }, []);

    return (
        <div className='flex-col gap-24'>
            {items && items.length > 0
                && <h1 className='text-30 f-600 mg-auto pdt-24'>
                    SẢN PHẨM MỚI 2024
                </h1>}
            <Slider {...settings} className={`slider-fix pdb-12 pdt-12 ${items.length < 4 ? `min-slider col-${items.length} normal` : ''}`}>
                {items.map((element: any) => (
                    <div>
                        <Link className={`item slider ${themelayout === 'dark' ? 'dark-mode' : 'white-mode'}`} to={`/chi-tiet/${element.id}`} key={element.id}>
                            <div className={`w-100 pointer box-shadow relative ${themelayout === 'dark' ? 'dark-mode' : 'white-mode'}`}>
                                <div className='devvn_label_product'>
                                    <span className="new">Sản phẩm mới</span>
                                </div>
                                <div className="item-frame-image">
                                    <img className='img-item bdr-t-4' src={element.listFiles.filter((img: string) => !img.includes('.mp4'))[0]} alt="" />
                                    {element.listFiles.filter((img: string) => !img.includes('.mp4')).length > 1 && <img className='img-item bdr-t-4 overlay-image' src={element.listFiles.filter((img: string) => !img.includes('.mp4'))[1]} alt="" />}
                                </div>

                                <div className="inf-item flex-col gap-8">
                                    <p className="text-13 f-400 text-center">{element.name}</p>
                                    <div className="flex-row flex-center gap-8 flex-wrap">
                                        <span className="text-13 f-500">{Number(element.priceSale).toLocaleString()} VNĐ</span>
                                        {element.price
                                            && <div className="flex-row flex-center relative">
                                                <span className="text-12 f-500 text-blur">{Number(element.price).toLocaleString()} VNĐ</span>
                                                <div className="line-center"></div>
                                            </div>
                                        }
                                    </div>
                                    <div className="flex-row flex-center gap-8 flex-wrap">
                                        <Rate disabled value={element.rate} className={`text-15 ${themelayout}`} />
                                        <span className="text-12 f-500 text-blur">{element.reviews} đánh giá</span>
                                    </div>
                                </div>

                            </div>
                        </Link>
                    </div>
                ))}
            </Slider>
        </div>
    )
}