import {getApp, initializeApp} from 'firebase/app'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
import { getDatabase } from 'firebase/database'; 

//Chỉ sử dụng trong quá trình phát triển
const firebaseConfig = {
  apiKey: "AIzaSyCpyVSTmGiv_OxiBVjCdsCcyzBDaXl4R54",
  authDomain: "nhangvip-8710c.firebaseapp.com",
  databaseURL: "https://nhangvip-8710c-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "nhangvip-8710c",
  storageBucket: "nhangvip-8710c.appspot.com",
  messagingSenderId: "175709508199",
  appId: "1:175709508199:web:13385e9022a80f2c2f3ac2",
  measurementId: "G-8EV7DFKF65"
};

const app = getApp.length > 0 ? getApp() : initializeApp(firebaseConfig)
const firestore = getFirestore(app)
const storage = getStorage(app)
const database = getDatabase(app);

export {app, firestore, storage, database}