import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { TypeStore } from "store/reducers";

// Định nghĩa type cho một mục trong menu
interface MenuItem {
    path: string;
    title?: string;
    open: boolean;
    hidden: boolean;
}

// Định nghĩa type cho state chứa menu
interface MenuState {
    system: {
        menu: MenuItem[];
    };
}

export function LinkHeader() {
    // Sử dụng type mới cho useSelector
    const { system } = useSelector((state: MenuState) => state);

    // Lọc và tạo danh sách các liên kết
    const listLinks = system.menu
        .filter((item) => item.open || item.hidden)
        .map((m) => ({
            key: `/${m.path}`,
            title: m.title?.toLocaleUpperCase()
        }));

    return (
        <>
            {/* Các liên kết được tạo động */}
            {listLinks.map((element) => (
                <Link className="color-w text-hover text-12" to={element.key} key={element.key}>
                    {element.title}
                </Link>
            ))}

            {/* Thay thế các dòng span ban đầu bằng các liên kết */}
            <Link className="color-w text-hover text-12" to="/gioi-thieu">GIỚI THIỆU</Link>
            <Link className="color-w text-hover text-12" to="/vong-tay-tram-huong">VÒNG TAY TRẦM HƯƠNG</Link>
            <Link className="color-w text-hover text-12" to="/nhang-tram-huong">NHANG TRẦM HƯƠNG</Link>
            <Link className="color-w text-hover text-12" to="/tram-huong-dot">TRẦM HƯƠNG ĐỐT</Link>
            <Link className="color-w text-hover text-12" to="/my-nghe-tram-huong">MỸ NGHỆ TRẦM HƯƠNG</Link>
        </>
    );
}
