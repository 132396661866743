import { Button } from "antd";
import React, { useEffect } from "react";
import './success.css'
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export function Success() {

    const history = useNavigate()

    useEffect(() => {
        const targetElement = document.getElementById('scroll-success');
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, []);

    return (
        <div className="main">
            <div className="default-container flex-col gap-32" id="scroll-success">
                <div className="flex-col flex-center success-container gap-12">
                    <BsFillCheckCircleFill className="icon-success" />
                    <h1 className="text-15 f-600">Đã đặt hàng thành công</h1>
                    <p className="text-13 f-400">
                        Chúng tôi sẽ sớm liên hệ lại với bạn. Cảm ơn bạn đã tin tưởng.
                    </p>
                    <Button type="dashed" onClick={() => history('/')}>
                        Trở về trang chủ
                    </Button>
                </div>
            </div>
        </div>
    )
}