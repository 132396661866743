import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { UserOutlined } from '@ant-design/icons'
import { firestore } from "firebase.config";
import LoadingSpinner from "components/lds-spinner/lds-spinner";
import { Avatar, Button, Col, Collapse, CollapseProps, Form, Input, Modal, Pagination, Rate, Row, message } from "antd";
import { FcCalendar } from "react-icons/fc";
import { FaRegCommentDots, FaRegEye } from "react-icons/fa";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { TypeStore } from "store/reducers";
import { saveCommentBlog, updateBlog, updateReviewBlog } from "service/firebase";
import { chuanHoaChuoi, smoothScroll } from "utils/index";
import TextArea from "antd/es/input/TextArea";

export function ChiTiet() {
    const [listContent, setListContent] = useState<string[]>([])
    const { themelayout } = useSelector((state: TypeStore) => state)
    const { _id } = useParams();
    const [isLoading, setIsLoading] = useState(false)
    const [blog, setBlog] = useState<any>(null)
    const [listTitle, setListTitle] = useState<string[]>([])
    const [listComments, setListComments] = useState<any[]>([])
    const [openComment, setOpenComment] = useState(false)
    const [rateComment, setRateComment] = useState(0)

    const [currentPage, setCurrentPage] = useState(1)
    // const [isLoadingComment ,setIsLoadingComment] = useState(false)

    const onFinishComment = async (values: any) => {
        try {
            setIsLoading(true)
            const existValue = blog
            const data = values
            data.rate = rateComment
            data.createdDay = String(dayjs())
            data.reply = null
            data.idBlog = _id
            const review = existValue ? existValue.reviews + 1 : 1
            const average = Math.ceil((existValue.rate + rateComment) / 2)

            await saveCommentBlog(data)
            if (_id) {
                await updateReviewBlog(_id, review, average)
            }
            setListComments((prev) => [...prev, data].sort((a, b) => (dayjs(a.createdDay).isAfter(dayjs(b.createdDay)) ? -1 : 1)))
            setBlog((prev: any) => ({
                ...prev,
                reviews: review,
                rate: average
            }))
            setIsLoading(false)
            setOpenComment(false)
            message.success('Tạo bình luận mới thành công');
        } catch (error) {
            message.error('Tạo bình luận mới thất bại');
        } finally {
            setIsLoading(false)
        }
    }

    const fetchComment = async () => {
        try {
            // setIsLoadingComment(true)
            if (_id) {
                const items = await getDocs(query(collection(firestore, "comments-blog"), where("idBlog", "==", _id)))
                const comments = items.docs.map((doc) => {
                    return { id: doc.id, ...doc.data() } as any
                })
                const sortComment = comments.sort((a, b) => (dayjs(a.createdDay).isAfter(dayjs(b.createdDay)) ? -1 : 1))
                setListComments(sortComment)
            }
        } catch (error) {
        }
    }

    const fetchblog = async () => {
        try {
            setIsLoading(true)
            if (_id) {
                const itemRef = doc(firestore, "blogs", _id);

                const data = await getDoc(itemRef)
                const blog = { id: data.id, ...data.data() } as any
                await updateBlog(_id, {
                    viewer: blog.viewer + 1
                })
                setBlog({
                    ...blog,
                    viewer: blog.viewer + 1
                })
                const content = String(blog.content).split('\n')
                const listTitle = content.filter((element) => element.includes('#'))
                setListTitle(listTitle)
                setListContent(content)
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchblog()
        fetchComment()
    }, [])

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: <p className={`text-13 bold ${themelayout === 'dark' ? 'white-color' : 'black-color'}`}>Danh mục</p>,
            children: listTitle.map((element) => {
                const title = element.replaceAll('#', ' ').trim()
                const scroll = chuanHoaChuoi(title)
                return <p className="dm-text" onClick={() => smoothScroll(scroll)}>{title}</p>
            }),
        },
    ];
    
    
  const pagedItems = useMemo(() => {
    const offset = (currentPage - 1) * 5;
    const startIndex = offset;
    const endIndex = offset + 5;
    return listComments.slice(startIndex, endIndex);
  }, [currentPage, listComments]);
  
    return (
        <div className="main">
            {isLoading && <LoadingSpinner />}
            <div className="default-container pd-12 blog">
                {blog && <div className="flex-col gap-12 mgt-12 mgb-24">
                    <h1 className="text-18 f-600">
                        {blog.title}
                    </h1>
                    <div className="flex-row flex-between">
                        <div className="flex-row gap-12 flex-center">
                            <Avatar icon={<UserOutlined />} className="box-shadow-w" />
                            <span className="text-13">By {blog.author}</span>
                        </div>
                        <div className="flex-row gap-32 flex-center">
                            <div className="flex-row gap-12 flex-center">
                                <FcCalendar className="text-30" />
                                <span className="text-13">
                                    {dayjs(blog.createdDate, 'DD/MM/YYYY').format('DD [Tháng] MM, YYYY')}
                                </span>
                            </div>
                            <div className="flex-row flex-center gap-12">
                                <div className="c-icon">
                                    <FaRegCommentDots className="text-12" />
                                </div>
                                <span className="text-13">
                                    {blog.reviews}
                                </span>
                            </div>
                            <div className="flex-row flex-center gap-12">
                                <div className="c-icon">
                                    <FaRegEye className="text-12" />
                                </div>
                                <span className="text-13">
                                    {blog.viewer}
                                </span>
                            </div>
                        </div>
                    </div>
                    <Collapse
                        className={themelayout}
                        items={items}
                    />
                    <div className="flex-row gap-12 align-center">
                        <Rate disabled value={blog.rate} className={`text-15 ${themelayout}`} />
                        <span className="text-12">
                            {blog.rate} / 5 - ({blog.reviews} bình chọn)
                        </span>
                    </div>
                    <div className="flex-col gap-12">
                        {listContent.map((element, index) => {
                            // const soLuongHash = element.match(/^#+/)
                            // const ketQua = soLuongHash ? soLuongHash[0].length : 0;
                            const replaceText = element.replaceAll('#', '').trim()
                            if (replaceText.includes('${')) {
                                const replaceImg = replaceText.replace('${', '').replace('}', '').trim()
                                const img = blog.listFiles.find((files: string) => files.includes(replaceImg))
                                return (
                                    <img className="img-blog" src={img} alt={replaceImg} />
                                )
                            } else {
                                if (element.slice(0, 1) === '#') {
                                    const scroll = chuanHoaChuoi(replaceText)
                                    return (
                                        <p className="text-13 bold" id={`${scroll}`}>{replaceText}</p>
                                    )
                                } else {
                                    return (
                                        <p className="text-13">{replaceText}</p>
                                    )
                                }
                            }
                        })}
                    </div>
                    <section className="flex-col gap-12 mgt-12">
                        <h1 className="text-15 f-600">ĐÁNH GIÁ BÀI VIẾT</h1>
                        <div className="flex-col gap-24">
                            <h1 className="text-14 f-600">{blog.reviews} đánh giá cho {blog.title}</h1>
                            <div className="flex-row flex-between">
                                <div className="review-item flex-row flex-center">
                                    <div className="comment-action pointer flex-row flex-center pdl-12 pdr-12" onClick={() => setOpenComment(true)}>
                                        <h1 className="text-15 f-600">Đánh giá ngay</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-col gap-12">
                            {
                                pagedItems.map((element) => (
                                    <div className="flex-col gap-8">
                                        <div className="flex-row gap-12">
                                            <h1 className="text-13 f-600">{element.authorName}</h1>
                                        </div>
                                        <div className="flex-row gap-8">
                                            <span className="text-13 f-400">
                                                <Rate value={element.rate} disabled className={`text-13 ${themelayout}`} style={{
                                                    marginRight: '0.5rem'
                                                }} /> {element.sub}
                                            </span>
                                        </div>
                                        <span className="text-13 text-link pointer">Bình luận {element?.createdDay
                                            ? <><span> • </span> <span className="text-13 f-400 text-blur">{dayjs(element?.createdDay).format('[Ngày] DD [tháng] MM [năm] YYYY')}</span></>
                                            : null}</span>
                                    </div>
                                ))
                            }
                            <div className={themelayout} style={{ marginLeft: 'auto' }}>
                                <Pagination showSizeChanger={false} responsive current={currentPage} onChange={setCurrentPage} total={listComments.length} pageSize={5} />
                            </div>
                        </div>
                        <Modal footer={null} title={blog.title}
                            centered open={openComment} onCancel={() => setOpenComment(false)}>
                            <Form layout="vertical" onFinish={(values) => onFinishComment(values)} className="form-design">
                                <Row gutter={[12, 12]}>
                                    <Col span={24}>
                                        <Form.Item name={'sub'} label="Nội dung" rules={[
                                            { required: true, message: 'Vui lòng nhập đánh giá' }
                                        ]}>
                                            <TextArea />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <p className="text-13 f-600">Bạn cảm thấy thế nào về bài viết? (Chọn sao)</p>
                                    </Col>
                                    <Col span={24}>
                                        <Rate onChange={(value) => setRateComment(value)} />
                                    </Col>
                                    <Col span={24}>
                                        <Row gutter={[12, 12]}>
                                            <Col span={24}>
                                                <Form.Item name={'authorName'} label="Họ và tên" rules={[
                                                    { required: true, message: 'Vui lòng nhập đánh giá' }
                                                ]}>
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name={'authorSdt'} label="Số điện thoại" rules={[
                                                    { required: true, message: 'Vui lòng nhập số điện thoại' },
                                                    { pattern: /^(\+84|0)\d{9,10}$/, message: 'Số điện thoại không hợp lệ' }
                                                ]}>
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name={'authorEmail'} label="Email" rules={[
                                                    { required: true, message: 'Vui lòng nhập email' },
                                                    {
                                                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                        message: 'Email không hợp lệ'
                                                    }
                                                ]}>
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Button type="primary" htmlType="submit">
                                                    Bình luận
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal>
                    </section>
                </div>}
            </div>

        </div>
    )
}