import React from 'react'
import { AiFillStar } from 'react-icons/ai'

interface DanhGiaType {
    listRate: number[],
    productName: string,
    starNum: {
        num: number,
        string: string
    },
    setOpen: (open: boolean) => void
}

export function DanhGia (props: DanhGiaType) {

    const {listRate, productName, starNum, setOpen} = props

    return <div className="flex-col gap-24">
    <h1 className="text-14 f-600">{listRate.length} đánh giá cho {productName}</h1>
    <div className="box-review">
        <div className="review-item flex-col gap-8 flex-center">
            <div className="flex-row gap-8 flex-center">
                <h1 className="color-y text-38 f-600">{starNum.string}</h1>
                <AiFillStar className="color-y text-38" />
            </div>
            <h1 className="text-12 f-600">ĐÁNH GIÁ TRUNG BÌNH</h1>
        </div>
        <div className="review-item center flex-col gap-8">
            {Array.of(5, 4, 3, 2, 1).map((element) => {
                const percent = listRate.length > 0 ? Math.max(Math.ceil((listRate.filter((item) => Number(item) === element).length * 100) / listRate.length), 0) : 0
                const length = listRate.filter((item) => Number(item) === element).length
                return (
                    <div className="flex-row gap-8">
                        <div className="text-13 f-400 flex-row flex-row-center flex-between star">
                            {element} <AiFillStar />
                        </div>
                        <div className="percent-row">
                            <div className="percent" style={{
                                gridColumn: `span ${percent} / span ${percent}`
                            }}></div>
                        </div>
                        <div className="flex-row gap-4 min-fit">
                            <h1 className="text-13 f-600 min-fit percent-count">{percent} %</h1>
                            <div className="line-row"></div>
                            <p className="text-13 f-400">{length} đánh giá</p>
                        </div>

                    </div>
                )
            })}
        </div>
        <div className="review-item flex-row flex-center">
            <div className="comment-action pointer flex-row flex-center" onClick={() => setOpen(true)}>
                <h1 className="text-15 f-600">Đánh giá ngay</h1>
            </div>
        </div>
    </div>
</div>
}