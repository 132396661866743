import React from "react";

export function CamKet() {
    return <div className="grid-col-4">
        <div className="grid-item-content">
            <div className="flex-row gap-8">
                <img src="/media/Group-1428.png" alt="Nhang" className="logo-img" />
                <div className="flex-col gap-8">
                    <h1 className="text-15 f-600">Cam kết 1 đổi 1</h1>
                    <p className="text-13 f-400">
                        Trong vòng 7 ngày
                    </p>
                </div>
            </div>
        </div>
        <div className="grid-item-content">
            <div className="flex-row gap-8">
                <img src="/media/Group.png" alt="Nhang" className="logo-img" />
                <div className="flex-col gap-8">
                    <h1 className="text-15 f-600">100% Uy tín</h1>
                    <p className="text-13 f-400">
                        Sản Phẩm Trầm Hương Tự Nhiên
                    </p>
                </div>
            </div>
        </div>
        <div className="grid-item-content">
            <div className="flex-row gap-8">
                <img src="/media/Group-1430.png" alt="Nhang" className="logo-img" />
                <div className="flex-col gap-8">
                    <h1 className="text-15 f-600">Giao hàng nhanh</h1>
                    <p className="text-13 f-400">
                        Đơn hàng được xử lý trong 24h
                    </p>
                </div>
            </div>
        </div>
        <div className="grid-item-content">
            <div className="flex-row gap-8">
                <img src="/media/security-on-2.png" alt="Nhang" className="logo-img" />
                <div className="flex-col gap-8">
                    <h1 className="text-15 f-600">Bảo hành 1 năm</h1>
                    <p className="text-13 f-400">
                        Hoàn toàn miễn phí
                    </p>
                </div>
            </div>
        </div>
    </div>
}