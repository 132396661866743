import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  limit,
  updateDoc,
  deleteDoc,
  getDoc,
  where,
  Firestore,
} from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';

import { firestore } from "../firebase.config";
import { message } from "antd";

export interface SearchItem {
  title: string;
}

export interface TypeData {
  id: string;
  title: string;
  imageURL: string;
  price: number;
  qty: number;
  description: string;
  listComment: TypeComment[];
}

export interface TypeReply {
  authorName: string,
  sub: string,
  createdDay?: string,
}

export interface TypeComment {
  authorEmail: string,
  authorName: string,
  authorSdt: string,
  id: string,
  idProduct: string,
  listFiles: string[],
  rate: number,
  reply: null | TypeReply[],
  sub: string,
  createdDay?: string
}

export const saveItem = async (data: TypeData) => {
  await setDoc(doc(firestore, "items", uuidv4()), data, { merge: true });
};

export const saveOrder = async (data: any) => {
  await setDoc(doc(firestore, "order", uuidv4()), data, { merge: true });
};

export const saveComment = async (data: TypeComment) => {
  await setDoc(doc(firestore, "comments", uuidv4()), data, { merge: true });
};

export const saveCommentBlog = async (data: TypeComment) => {
  await setDoc(doc(firestore, "comments-blog", uuidv4()), data, { merge: true });
};

export const updateBlog = async (id: string, data:any) => {
  const itemRef = doc(firestore, "blogs", id);
  await updateDoc(itemRef, data);
}

export const updateItem = async (id: string, count: number) => {
  const itemRef = doc(firestore, "items", id);
  await updateDoc(itemRef, {
    purchases: count
  });
}

export const updateReviewItem = async (id: string, count: number, rageRate: any[], rate: number) => {
  const itemRef = doc(firestore, "items", id);
  await updateDoc(itemRef, {
    reviews: count,
    rageRate: rageRate,
    rate: rate
  });
}

export const updateReviewBlog = async (id: string, count: number, rate: number) => {
  const itemRef = doc(firestore, "blogs", id);
  await updateDoc(itemRef, {
    reviews: count,
    rate: rate
  });
}

export const removeItem = async (id: string) => {
  const itemDocRef = doc(firestore, "items", id);
  await deleteDoc(itemDocRef);
}

export const getItemById = async (id: string) => {
  const itemRef = doc(firestore, "items", id);
  const data: any = await getDoc(itemRef)
  return { id: data.id, ...data.data() }
}

export const getCategories = async () => {
  const items = await getDocs(query(collection(firestore, "categories")))
  const data = items.docs.map((doc) => {
    return { id: doc.id, ...doc.data() } as any
  })
  return data;
}

export const checkDocumentExists = async (collectionName: string, key: string, data: any) => {
  const items = await getDocs(query(collection(firestore, collectionName), where(key, "==", data)))
  return !items.empty
};

export const getListDataByCategories = async (categories: string) => {
  const items = await getDocs(query(collection(firestore, "items"), where("type", "==", categories)))
  const data = items.docs.map((doc) => {
    return { id: doc.id, ...doc.data() } as any
  })
  return data;
}

export const getCommentsItemById = async (id: string) => {
  const items = await getDocs(query(collection(firestore, "comments"), where("idProduct", "==", id), limit(5)))
  const data = items.docs.map((doc) => {
    return { id: doc.id, ...doc.data() } as any
  })
  return data;
}

export const updateReply = async (commentId: string, reply: TypeReply) => {
  const commentRef = doc(firestore, "comments", commentId);

  await updateDoc(commentRef, {
    reply: reply
  });
};

export const searchItems = async (searchQuery: string) => {
  try {
    const q = query(
      collection(firestore, "items"),
      where("name", ">=", searchQuery),
      where("name", "<=", searchQuery + "\uf8ff")
    );
    const querySnapshot = await getDocs(q);

    const results: SearchItem[] = [];
    querySnapshot.forEach((doc) => {
      results.push({ id: doc.id, ...doc.data() } as any);
    });
    return results;
  } catch (error) {
    message.error('Lấy danh sách sản phẩm lỗi')
    return []
  }
};