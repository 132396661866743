import React, { useEffect, useState } from "react";
import './history.css'
import { Empty, Pagination, message, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import { useDispatch, useSelector } from "react-redux";
import { TypeStore, dispatchAuthenModal } from "store/reducers";
import { DocumentData, QueryDocumentSnapshot, collection, getCountFromServer, getDocs, limit, query, startAfter, where } from "firebase/firestore";
import { firestore } from "firebase.config";
import './history.css'
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "components/lds-spinner/lds-spinner";

export function History() {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const history = useNavigate()

    const dispatch = useDispatch()

    const { userLogin } = useSelector((state: TypeStore) => state)

    const [itemsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [items, setItems] = useState<any[]>([]);
    const [lastDoc, setLastDoc] = useState<QueryDocumentSnapshot<DocumentData, DocumentData> | null>(null);
    const [count, setCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const loadMore = async () => {
        try {
            setIsLoading(true)
            let q = query(collection(firestore, 'order'), where("userId", "==", userLogin.uid));
            q = query(q, limit(itemsPerPage));
            if (lastDoc) {
                q = query(q, startAfter(lastDoc));
            }
            const coll = query(collection(firestore, 'order'), where("userId", "==", userLogin.uid));
            const snapshot = await getCountFromServer(coll);
            const docCount = snapshot.data().count
            setCount(docCount)
            await getDocs(q)
                .then((querySnapshot) => {
                    const newItems: any[] = [];
                    querySnapshot.forEach((doc) => {
                        newItems.push({ id: doc.id, ...doc.data() } as any);
                    });

                    if (newItems.length > 0) {
                        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
                        setItems(newItems);
                    }
                })
                .catch((error) => {
                    console.error('Error getting documents: ', error);
                });
            setIsLoading(false)
        } catch (error) {
            message.error('Lấy thông tin người dùng thất bại')
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        if (userLogin != null) {
            loadMore();
            dispatch(dispatchAuthenModal(null))
        } else {
            setItems([])
            dispatch(dispatchAuthenModal('signin'))
        }
    }, [itemsPerPage, userLogin, currentPage]);

    useEffect(() => {
        const targetElement = document.getElementById('scroll-history');
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, []);

    return (
        <div className="main">
            <div id="scroll-history">
                {isLoading && <LoadingSpinner />}
                <div className="flex-col gap-32 default-container">
                    <h1 className="text-15 f-600 text-center">
                        LỊCH SỬ MUA HÀNG
                    </h1>
                    {items.length > 0
                        ? <div className="flex-col gap-12 frame-pagination">
                            <div className="flex-col box-pagination">
                                <div className="grid-temp-pagination header">
                                    <div className="grid-temp-item col-10 text-14 f-600">Sản phẩm</div>
                                    <div className="grid-temp-item text-14 f-600 flex-row flex-center">Giá</div>
                                    <div className="grid-temp-item text-14 f-600 flex-row flex-center">Số lượng</div>
                                    <div className="grid-temp-item text-14 f-600 flex-row flex-center">Thuộc loại</div>
                                    <div className="grid-temp-item text-14 f-600 flex-row flex-center">Thao tác</div>
                                </div>
                                {items.map((element, index: number) => (
                                    element.products.map((item: any) => (
                                        <div className={`grid-temp-pagination items bd-b ${index % 2 === 0 ? 'table-row-1' : 'table-row-2'}`}>
                                            <div className="grid-temp-item col-10 text-14 f-600 flex-row gap-12">
                                                <img className="image-product" src={item.listFiles[0]} alt="San pham" />
                                                <p className="text-14 f-400">{item.name}</p>
                                            </div>
                                            <div className="grid-temp-item text-14 f-400 flex-row flex-center">{Number(item.priceSale).toLocaleString()} VND</div>
                                            <div className="grid-temp-item text-14 f-400 flex-row flex-center">{item.qty}</div>
                                            <div className="grid-temp-item text-14 f-400 flex-row flex-center">{item.type}</div>
                                            <div className="grid-temp-item text-14 f-400 flex-row flex-center pointer text-link" onClick={() => history(`/chi-tiet/${item.id}`)}>Xem lại</div>
                                        </div>
                                    ))
                                ))}
                            </div>
                            <Pagination hideOnSinglePage showSizeChanger={false} responsive current={currentPage} total={count} pageSize={5} onChange={(value) => setCurrentPage(value)} />
                        </div>
                        : <Empty />
                    }
                </div>
            </div>
        </div>
    )
}