import { Rate } from 'antd'
import dayjs from 'dayjs'
import { CommentType } from 'modal/index'
import React from 'react'
import { BsCheck2Circle } from 'react-icons/bs'

interface BinhLuanType {
    listComments: CommentType[],
    themelayout: string,
    setSelectedImage: (image: string) => void
}

export function BinhLuan(props: BinhLuanType) {

    const {listComments, setSelectedImage, themelayout} = props

    return <>
        {
            listComments.map((element) => (
                <div className="flex-col gap-8">
                    <div className="flex-row gap-12">
                        <h1 className="text-13 f-600">{element.authorName}</h1>
                        {(element?.purchased != null && element.purchased === true) &&
                            <p className="woocommerce-review__verified flex-row gap-4 flex-center">
                                <BsCheck2Circle />
                                Đã mua hàng tại nhangbinhan.com
                            </p>}
                    </div>
                    <div className="flex-row gap-8">
                        <span className="text-13 f-400">
                            <Rate value={element.rate} disabled className={`text-13 ${themelayout}`} style={{
                                marginRight: '0.5rem'
                            }} /> {element.sub}
                        </span>
                    </div>
                    <div className="flex-row gap-8 flex-wrap">
                        {element.listFiles.map((image: string) => (
                            <img className="comment-img" src={image} alt="comment" onClick={() => setSelectedImage(image)} />
                        ))}
                    </div>
                    {element.reply
                        ? <div className="flex-col">
                            <span className="text-13" >Đã trả lời {element?.createdDay
                                ? <><span> • </span> <span className="text-13 f-400 text-blur">{dayjs(element?.createdDay).format('[Ngày] DD [tháng] MM [năm] YYYY')}</span></>
                                : null}</span>
                            <div className="rectangle flex-col gap-8">
                                <div className="triangle"></div>
                                <h1 className="text-13 f-600">{element.reply.authorName}</h1>
                                <p className="text-13 f-400">{element.reply.sub}</p>
                                <p className="text-13 f-400 text-blur">
                                    {dayjs(element.reply.createdDay).format('[Ngày] DD [tháng] MM [năm] YYYY')}
                                </p>
                            </div>
                        </div>
                        : <span className="text-13 text-link pointer">Trả lời {element?.createdDay
                            ? <><span> • </span> <span className="text-13 f-400 text-blur">{dayjs(element?.createdDay).format('[Ngày] DD [tháng] MM [năm] YYYY')}</span></>
                            : null}</span>}
                </div>
            ))
        }
    </>
}