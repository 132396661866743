import React, { useState, useEffect, useCallback } from 'react';
import { Collapse } from 'antd';
import './footer.css';
import { listIconsStore } from 'utils/index';
import { useSelector } from 'react-redux';
import { TypeStore } from 'store/reducers';
import { Link } from 'react-router-dom';

const { Panel } = Collapse;

export function Footer() {
    const { system } = useSelector((state: TypeStore) => state);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 665);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 665);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const openFile = (url: string) => {
        window.open(url, '_blank');
    };

    const mergedArray = (originalArray: any[]) => {
        const mergedArray: any[] = [];
        const mergedObject: any = {};
        originalArray.map(item => {
            if (!mergedObject[item.title]) {
                mergedObject[item.title] = {
                    key: item.title,
                    data: []
                };
                mergedArray.push(mergedObject[item.title]);
            }
            mergedObject[item.title].data.push({
                child: item.child,
                path: item.path
            });
        });
        return mergedArray;
    }

    const renderFooterColumns = useCallback(() => {
        return (
            <Collapse style={{ width: '100%' }} defaultActiveKey={'1'} bordered={false} accordion={isSmallScreen}>
                {mergedArray(system.footer).map((e, index) => (
                    <Panel className='footer-title' header={`${e.key}`} key={`${index + 1}`}>
                        {e.data.map((x: any, dataIndex: number) => (
                            <Link key={dataIndex} to={x.path} className='footer-content'>{x.child}</Link>
                        ))}
                    </Panel>
                ))}
            </Collapse>
        );
    }, [isSmallScreen, system]);

    return (
        <footer className="flex-row footer-bg">
            <div className="footer component-container flex-col gap-24">
                <div className='flex-row flex-row-center flex-evenly gap-32 flex-wrap'>
                    <img src="/media/logo/logo-no-bg.png" alt="Nhang binh an" className='logo-footer' />
                </div>
                {isSmallScreen ? (
                    renderFooterColumns()
                ) : (
                    <div className="footer flex-row gap-32">
                        {mergedArray(system.footer).map((e, index) => (
                            <div key={index} className="footer-column">
                                <h3 className='footer-title'>{e.key}</h3>
                                {e.data.map((x: any, dataIndex: number) => (
                                    <Link key={dataIndex} to={x.path} className='footer-content'>{x.child}</Link>
                                ))}
                            </div>
                        ))}
                    </div>
                )}
                <div className="flex-row gap-12 flex-center">
                    {listIconsStore.map((element, index) => {
                        const data: any = system;
                        const url = data[element.alt] ?? '';
                        return (
                            <div key={index} onClick={() => openFile(url)} className="flex-row box-shadow bdr-4 flex-center pd-4 bd-w pointer hover-scale"
                                style={{
                                    width: '50px',
                                    height: '50px'
                                }}
                            >
                                <img src={element.src} alt={element.alt} style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain'
                                }} />
                            </div>
                        );
                    })}
                </div>
                <div className="row">
                    Copyright © 2024 - All rights reserved || Designed By: TRẦM HƯƠNG BÌNH AN 1
                </div>
            </div>
        </footer>
    );
}
